<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar :to="`/areas/${$route.params.idArea}/box`" />
        </v-col>
      </v-row>
    </v-card-actions>

    <box-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-editar-nome="carregandoEditarNome"
      :readonly="readonly"
      salvarDisabled
      :validacao-formulario="validacaoFormulario"
      :box="box"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import boxes from "@/api/boxes";

export default {
  components: {
    BoxCampos: () => import("./BoxCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
     
      carregandoEditarNome: true,
      readonly: true,
      validacaoFormulario: {},
      box: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await boxes.buscar(
          this.$route.params.idArea,
          this.$route.params.id
        );

        var box = {
          descricao: resposta.data.data.descricao,
          ativo: resposta.data.data.ativo,
        };

        this.box = box;
      } catch (e) {
        this.$router.push("/box", () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(box) {
      this.carregandoSalvar = true;

      try {
        await boxes.atualizar(
          this.$route.params.idArea,
          this.$route.params.id,
          box
        );
        this.$router.push(`/areas/${this.$route.params.idArea}/box`, () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
