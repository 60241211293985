import axios from '@/plugins/axios'

export default {

    async buscar (idArea, id) { 
        return await axios.get(`areas/${idArea}/boxes/${id}`)
    },

    async inserir (idArea, dados) {
        console.log(dados, "inserir")
        return await axios.post(`areas/${idArea}/boxes`, dados)
    },

    async atualizar (idArea, id, dados) {
        return await axios.put(`areas/${idArea}/boxes/${id}`, dados)
    },

    async deletar (id) {
        return await axios.delete(`areas/${id}`)
    },
}
